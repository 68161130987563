<template>
  <div></div>
</template>

<script>
export default {
  beforeCreate() {
    let liffId = this.$liff_ID_Buzzebees;
    this.$liff
      .init({ liffId: liffId })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.sendReport();
              this.openBuzzebees(this.lineProfile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.sendReport();
            this.openBuzzebees(this.lineProfile.userId);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {},

  data() {
    return {};
  },
  methods: {
    async sendReport() {
      if (!this.$route.query.linerichid) {
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        LineId: this.lineProfile.userId,
        LineRichId: this.$route.query.linerichid || "0",
        LineRichActionId: this.$route.query.linerichactiontid || "0",
        Sort: this.$route.query.sort || "0"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/ReportRichMenu`, requestOptions)
        .then(response => response.json())
        .then(result => {});
    },
    async openBuzzebees(id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginbuzzebees/${id}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            window.open(result.detail.url, "_self");
          } else {
            window.open("https://skl.co.th", "_self");
          }
        })
        .catch(error => console.log("error", error));
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style></style>
